import * as React from "react";
import { css } from "@emotion/css";

import { Block, Button, Page, Section, SubSection } from "../components";

import { h4Style, p2Style, p3Style } from "../globalstyle";

import img01 from "../images/owen-beard-dk8jxx1b-1c-unsplash.jpg";
import Emeline from "../images/emeline.jpg";
import Adrien from "../images/adrien.jpg";
import Alexandre from "../images/alexandre.jpg";
import Yvonne from "../images/yvonne.jpg";
import empty from "../images/empty.jpg";
import img02 from "../images/blog/article_mag_metropole_v2.png";
import img03 from "../images/blog/post_frenchiot-1.png";
import img04 from "../images/blog/dotter_usage_innovant.png";
import img05 from "../images/blog/img_2427.jpg";
import img06 from "../images/blog/orangeentrepreneuses.jpg";
import img07 from "../images/blog/speakup_emn.png";

const Equipe = () => {
  return (
    <Page tabTitle="Équipe" pageTitle="Équipe">
      <Section color="light-gray" pSize={2}>
        <SubSection flex={1}>
          <img
            src={img01}
            alt="Notre objectif"
            className={css`
              padding: 20px;
              height: 60vh;
              @media screen and (max-width: 1000px) {
                height: 40vh;
              }
            `}
          />
        </SubSection>
        <SubSection flex={3} title="Notre objectif">
          <p className="content">
            Chez KerNel Biomedical, nous avons pour objectif de faciliter la vie
            des chercheurs en développant de nouvelles solutions numériques au
            service de la santé.
          </p>
          <p className="content">
            En créant la plateforme <b>Dotter.science</b>, nous tentons de
            rendre la recherche en santé plus accessible et plus efficiente, en
            permettant aux chercheurs de disposer d’une technologie conviviale
            pour la collecte et le management des données.
          </p>
          <Button link="http://www.kernelbiomedical.com/" color="blue">
            EN SAVOIR PLUS SUR KERNEL BIOMEDICAL
          </Button>
        </SubSection>
      </Section>
      <Block
        className={css`
          width: 60vw;
          display: flex;
          flex-direction: column;
          & .member {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
          }
          & img {
            height: 240px;
          }
          @media screen and (max-width: 800px) {
            width: 90vw;
            & .member {
              width: 90vw;
              padding-bottom: 20px;
            }
          }
        `}
      >
        <h4 className={h4Style}>Notre équipe</h4>
        <div
          className={css`
            @media screen and (max-width: 1000px) {
              width: 80vw;
            }
          `}
        >
          <div
            className={css`
              display: flex;
              justify-content: center;
              & .member {
                flex: 1;
                max-width: 400px;
                margin: 20px;
              }
              @media screen and (max-width: 800px) {
                flex-direction: column;
                align-items: center;
              }
            `}
          >
            <div className="member">
              <img src={Emeline} alt="Émeline Fresnel" />
              <p className={p3Style}>
                <b>Emeline Fresnel</b>
                <br />
                Cofondatrice et CEO
              </p>
              <p className={p3Style}>
                Docteure en ingénierie biomédicale, Emeline fait l’interface
                avec les chercheurs pour définir les orientations stratégiques
                de Dotter.science et gère sa commercialisation.
              </p>
            </div>
            <div className="member">
              <img src={Adrien} alt="Adrien Kerfourn" />
              <p className={p3Style}>
                <b>Adrien Kerfourn</b>
                <br />
                Cofondateur et CTO
              </p>
              <p className={p3Style}>
                Docteur en sciences de l’ingénieur, Adrien s’attache à
                développer un logiciel performant et sécurisé pour la recherche
                en santé et gère la relation client.
              </p>
            </div>
          </div>
          <div
            className={css`
              display: flex;
              & .member {
                flex: 1;
                max-width: 400px;
                margin: 20px;
              }
              @media screen and (max-width: 800px) {
                flex-direction: column;
                align-items: center;
              }
            `}
          >
            <div className="member">
              <img src={Alexandre} alt="Alexandre Bouin" />
              <p className={p3Style}>
                <b>Alexandre Bouin</b>
                <br />
                Développeur FullStack
              </p>
            </div>
            <div className="member">
              <img src={Yvonne} alt="Yvonne Baye" />
              <p className={p3Style}>
                <b>Yvonne Baye</b>
                <br />
                Responsable du développement commercial
              </p>
              <p className={p3Style}></p>
            </div>
          </div>
        </div>
      </Block>
      <Section color="blue" title="Dernières actus et événements">
        <SubSection>
          <div
            className={css`
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              grid-gap: 4px;
              & img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            `}
          >
            <img
              src={img02}
              alt="Article : simplifier la recherche médicale"
              className={css`
                grid-column-start: 1;
                grid-column-end: 1;
              `}
            />
            <img
              src={img03}
              alt="Dotter.science - Lauréat 2019"
              className={css`
                grid-column-start: 2;
                grid-column-end: 4;
              `}
            />
            <img
              src={img04}
              alt="Carrefour des Possibles - Usages innovants du numérique"
              className={css`
                grid-column-start: 1;
                grid-column-end: 4;
              `}
            />
            <img
              src={img05}
              alt="Stand Dotter.science"
              className={css`
                grid-column-start: 1;
                grid-column-end: 3;
              `}
            />
            <div
              className={css`
                display: grid;
                grid-column-start: 3;
                grid-column-end: 4;
                grid-template-rows: 1fr 1fr;
                grid-gap: 4px;
              `}
            >
              <img src={img06} alt="Meeting Dotter.science" />
              <img src={img07} alt="Conférence Dotter.science" />
            </div>
          </div>
        </SubSection>
      </Section>
    </Page>
  );
};

export default Equipe;
